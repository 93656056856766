import { Routes, Route } from "react-router-dom";
import DashboardView from "../pages/admin/Dashboard";
import UserManager from "../pages/admin/UserManager";
import MembersManager from "../pages/admin/MembersManager";
import PaymentManager from "../pages/admin/PaymentManager";

// common masters
import LocationManager from "../pages/admin/LocationManager";
import CategoryManager from "../pages/admin/CategoryManager";
import RolesManager from "../pages/admin/RolesManager";
import PhotoGalleryMaster from "../pages/admin/PhotoGalleryMaster";
import VideoGalleryMaster from "../pages/admin/VideoGalleryMaster";
import BannerMaster from "../pages/admin/BannerMaster";
import PlansMaster from "../pages/admin/PlansMaster";

// advance masters
import FacilityManager from "../pages/admin/FacilityManager";
import ActivityManager from "../pages/admin/ActivityManager";
import BatchManager from "../pages/admin/BatchManager";


import EnrollActivityManager from "../pages/admin/EnrollActivityManager";
import BookingManager from "../pages/admin/BookingManager";
import EventManager from "../pages/admin/EventManager";
import ContactLeads from "../pages/admin/ContactLeadsContainer";

// CMS Routes
import Faqs from "../pages/admin/Faqs";

// Reports Routes
import Reports from "../pages/admin/Reports";

const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<DashboardView />} />
            <Route path="/dashboard" element={<DashboardView />} />
            <Route path="/user-manager" element={<UserManager />} />
            <Route path="/members-manager" element={<MembersManager />} />
            <Route path="/payment-manager" element={<PaymentManager />} />

            {/* Common Masters */}
            <Route path="/location-manager" element={<LocationManager />} />
            <Route path="/category-manager" element={<CategoryManager />} />
            <Route path="/roles-manager" element={<RolesManager />} />
            <Route path="/photo-gallery-master" element={<PhotoGalleryMaster />} />
            <Route path="/video-gallery-master" element={<VideoGalleryMaster />} />
            <Route path="/banner-master" element={<BannerMaster />} />
            <Route path="/plans-master" element={<PlansMaster />} />

            {/* Advance Masters */}
            <Route path="/facility-manager" element={<FacilityManager />} />
            <Route path="/activity-master" element={<ActivityManager />} />
            <Route path="/batch-master" element={<BatchManager />} />

            <Route path="/enroll-activity" element={<EnrollActivityManager />} />
            <Route path="/bookings-manager" element={<BookingManager />} />
            <Route path="/event-manager" element={<EventManager />} />

            <Route path="/contact-leads" element={<ContactLeads />} />

            {/* CMS Routes */}
            <Route path="/faqs" element={<Faqs />} />

            {/* Reports Routes */}
            <Route path="/members-reports" element={<Reports type="members" />} />
            <Route path="/enrollment-reports" element={<Reports type="enrollment" />} />
        </Routes>
    );
};

export default AdminRoutes;