import React, { useEffect } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import DatePickerComponent from "../../../components/Common/DatePicker";
import BasicSelect from "../../../components/Common/Select";
import Button from "../../../components/Common/Button";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import { axios } from "../../../helpers/axios";
import { format } from "date-fns";

const ReportsManagerContainer = ({ type: reportType }) => {

    const dispatch = useDispatch();
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [type, setType] = React.useState(reportType);
    const [exporting, setExporting] = React.useState(false);

    const handleExport = async () => {
        try {
            if (type && startDate && endDate) {
                setExporting(true);

                const hitUrl = `/reports/${type}?start_date=${format(startDate, "yyyy-MM-dd")}&end_date=${format(endDate, "yyyy-MM-dd")}`;

                let response = await axios.get(hitUrl, { responseType: "blob" });

                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${type}_${format(startDate, "yyyy-MM-dd")}_to_${format(endDate, "yyyy-MM-dd")}.csv`); // Filename
                document.body.appendChild(link);
                link.click();

                setExporting(false);
            } else {
                dispatch(setSnackBar({
                    open: true,
                    message: "Please select report type and date range",
                    severity: "error",
                }))
            }
        } catch (e) {
            setExporting(false);
        }
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={6} sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">Reports</Typography>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container gap={2}>
                    {/* <Grid item xs={12} md={3}>
                        <BasicSelect
                            size="small"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            displayEmpty
                            label="Select Report Type"
                            name="type"
                            id="type"
                            items={[
                                { label: "Member Report", value: "members" },
                                { label: "Enrollment Report", value: "enrollment" },
                                { label: "Booking Report", value: "bookings" },
                            ]}
                        />
                    </Grid> */}
                    <Grid item xs={12} md={3}>
                        <DatePickerComponent
                            id={"start_date"}
                            name={"start_date"}
                            label="Start Date"
                            onChange={(val) => setStartDate(val)}
                            value={startDate}
                            fullWidth
                            minDate={new Date(new Date().setMonth(new Date().getMonth() - 5))} // minimum date is last three month
                            maxDate={new Date()} // max date is today
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePickerComponent
                            id={"end_date"}
                            name={"end_date"}
                            label="End Date"
                            onChange={(val) => setEndDate(val)}
                            value={endDate}
                            fullWidth
                            minDate={startDate} // min date is start date
                            maxDate={new Date()} // max date is today
                        />
                    </Grid>
                </Grid>
                <Grid container pt={2} justifyContent="flex-end" gap={2}>
                    <Button size="large" type="submit" fullWidth={false} loading={exporting} onClick={() => handleExport()}>Export & Download</Button>
                </Grid>
            </Paper>
        </Stack >
    );
}

export default ReportsManagerContainer;