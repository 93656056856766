import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeMastersInitialStateRoles, handlePaginationStateRoles } from "../../../store/masters/mastersSlice";

const mapStateToProps = (state) => {
    return {
        // location
        formType: state.masters.formTypeRoles,
        initialValues: state.masters.initialValuesRoles,
        showDrawer: state.masters.showDrawerRoles,
        pagination: state.masters.paginationRoles,
    };
};

const mapDispatch = {
    changeRolesInitialState: changeMastersInitialStateRoles,
    handlePaginationState: handlePaginationStateRoles,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;