import React, { Fragment } from "react";
import { Chip, TableCell, TableRow, Typography } from "@mui/material";
import TableCommon from "../../Common/Table";
import { handleDateTimeDefault, JsonDecode } from "../../../helpers/utils";

const PaymentTableComponent = ({ loading, fetching = false, count, data, pagination, handlePagination }) => {
    let columns = [
        {
            title: "Sr. No.",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Member Details",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Booking Details",
            sort: false,
            minWidth: 170,
        },
        {
            title: "Amount Paid",
            sort: false,
            minWidth: 170,
        },
        {
            title: "Payment Details",
            sort: false,
            minWidth: 170,
        },
        {
            title: "Payment Date",
            field: "createdAt",
            sort: true,
            minWidth: 170,
        },
        {
            title: "Payment Status",
            field: "payment_status",
            sort: true,
            minWidth: 90,
        },
    ];

    console.log(data, "data");
    

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            const paymentRes = JsonDecode(row?.payment_response);
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{(index + 1) + (pagination.pageNo * pagination.limit)}</TableCell>
                    <TableCell align="center">
                        {
                            (row?.bookings_data && row?.bookings_data.length > 0) ? row?.bookings_data.map(function (data, key) {
                                const familyData = data?.family_member && data?.family_member.length > 0 && data?.family_member?.[0]?.name ? data?.family_member[0] : null;

                                if (familyData)
                                    return (
                                        <Fragment>
                                            <Typography variant="subtitle2" key={key}>Secondary Member</Typography>
                                            <Typography variant="subtitle2"><strong>Member Id:</strong> {row?.member_data?.member_id}</Typography>
                                            <Typography variant="subtitle2" key={key}> {familyData?.name}</Typography>
                                        </Fragment>
                                    )
                                else return (
                                    <Fragment>
                                        <Typography variant="subtitle2" key={key}>Primary Member</Typography>
                                        <Typography variant="subtitle2"><strong>Member Id:</strong> {row?.member_data?.member_id}</Typography>
                                        <Typography variant="subtitle2">{row?.member_data?.name}</Typography>
                                    </Fragment>
                                )
                            }) : (
                                <Fragment>
                                    <Typography variant="subtitle2">Primary Member</Typography>
                                    <Typography variant="subtitle2"><strong>Member Id:</strong> {row?.member_data?.member_id}</Typography>
                                    <Typography variant="subtitle2">{row?.member_data?.name}</Typography>
                                </Fragment>
                            )
                        }

                    </TableCell>
                    <TableCell align="center">
                        {
                            row?.bookings_data && row?.bookings_data.map(function (data, key) {
                                return (
                                    <Fragment>
                                        <Typography variant="subtitle2"><strong>Booking Id:</strong> {data?.booking_id}</Typography>
                                        <Typography variant="subtitle2" key={key}><strong>Activity Name:</strong> {row?.activity_data[key]?.name} - {row?.activity_data[key]?.activity_id}</Typography>
                                    </Fragment>
                                )

                            })
                        }
                        {
                            row?.plans_data && row?.plans_data.map(function (data, key) {
                                return (
                                    <Fragment>
                                        <Typography variant="subtitle2" key={key}><strong>Plan Name:</strong> {data?.plan_name}</Typography>
                                        <Typography variant="subtitle2" key={key}><strong>Plan Type:</strong> {data?.plan_type}</Typography>
                                    </Fragment>
                                )
                            })
                        }
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">{row?.amount_paid} Rs.</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">Payment Id: {paymentRes?.tracking_id}</Typography>
                        <Typography variant="subtitle2">Payment Mode: {row?.payment_mode}</Typography>
                    </TableCell>
                    <TableCell align="center">{handleDateTimeDefault(row?.createdAt)}</TableCell>
                    <TableCell align="center">{row?.payment_status === "Success" ? <Chip label="Success" color="success" /> : row?.payment_status === "Failed" ? <Chip label="Failed" color="error" /> : <Chip label="Pending" color="info" />}</TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={7} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} count={count} loading={loading || fetching} pagination={pagination} handlePagination={handlePagination} />
    )
}

export default PaymentTableComponent;